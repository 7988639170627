import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo"
import Content, { HTMLContent } from "../components/Content"
import "../styles/pages/about.css"
import PageTransitionContainer from "../components/PageTransitionContainer"

const AboutPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <section id="about">
      <h1 className="page-heading">{title}</h1>
      <PageContent className="content" content={content} />
    </section>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func
}

const AboutPage = ({ data, location }) => {
  const { markdownRemark: post } = data

  return (
    <PageTransitionContainer>
      <Layout location={location} page="about">
        <Seo
          title="About Us | RateLock"
          description={`${post.html}`}
          location={location}
        />
        <AboutPageTemplate
          contentComponent={HTMLContent}
          title={post.frontmatter.title}
          content={post.html}
        />
      </Layout>
    </PageTransitionContainer>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
